import { functions } from '../../firestore';

export default class {
  constructor() {
    this.imagesCall = 'shepherd/getImages';
    this.imageTagCall = 'shepherd/getImageTags';
    this.imageJobPrefix = 'europe-west2-docker.pkg.dev/digital-intelligence-208511/cloud-build/shepob-';
  }

  getAllImages() {
    return new Promise((resolve) => {
      const getImages = functions.httpsCallable(this.imagesCall);
      getImages().then((result) => {
        resolve(result.data);
      });
    });
  }

  getImageTags(imageRef) {
    return new Promise((resolve, reject) => {
      const getImages = functions.httpsCallable(this.imageTagCall);
      getImages({ imageRef }).then((result) => {
        resolve(result.data);
      }).catch(() => {
        reject();
      });
    });
  }

  getJobImageList() {
    return new Promise((resolve) => {
      this.getAllImages()
        .then((allImages) => {
          if (!allImages) resolve([]);
          const images = {};
          allImages.forEach((image) => {
            if (!this.isJobImage(image)) return;
            images[image] = this.getJobImageNiceName(image);
          });
          resolve(images);
        });
    });
  }

  getJobImageNiceName(image) {
    if (!this.isJobImage(image)) return image;
    return image.substring(this.imageJobPrefix.length - 1)
      .replace(/-([a-z])/g, g => ` ${g[1].toUpperCase()}`);
  }

  isJobImage(image) {
    return image.substring(0, this.imageJobPrefix.length) === this.imageJobPrefix;
  }
}
