import Vue from 'vue';
import moment from 'moment';

const Clock = new Vue({
  created() {
    this.listeners = 0; // set non-reactive counter
  },
  methods: {
    start() {
      this.interval = setInterval(this.emit, 10000);
    },
    emit() {
      this.$emit('tick');
    },
    register(cb) {
      if (!cb) return;
      if (this.listeners === 0) {
        this.start(); // start clock
      }
      this.listeners += 1;
      this.$on('tick', cb);
    },
    unregister(cb) {
      if (!cb) return;
      this.listeners -= 1;
      this.$off('tick', cb);
      if (this.listeners === 0) {
        clearInterval(this.interval); // turn off clock
      }
    },
  },
});

export default {
  name: 'FromNow',
  props: {
    tag: { type: String, default: 'span' },
    value: { type: [Object, Date, String], default: () => moment().toISOString() },
    dropFixes: {
      default: false,
      type: Boolean,
    },
    interval: { type: Number, default: 1000 },
  },
  data() {
    return { fromNow: this.momentValue().fromNow(this.dropFixes) };
  },
  mounted() {
    Clock.register(this.updateFromNow);
    this.$watch('value', this.updateFromNow);
  },
  beforeDestroy() {
    Clock.unregister(this.intervalId);
  },
  methods: {
    momentValue() {
      return typeof this.value === 'object' && typeof this.value.seconds === 'number'
        ? moment(new Date(this.value.seconds * 1000))
        : moment(this.value);
    },
    updateFromNow() {
      const newFromNow = this.momentValue().fromNow(this.dropFixes);
      if (newFromNow !== this.fromNow) {
        this.fromNow = newFromNow;
      }
    },
  },
  render(h) {
    return h(this.tag, { key: this.fromNow }, this.fromNow);
  },
};
