import { functions } from '../../firestore';

export default class {
  constructor() {
    this.secretCall = 'shepherd/getSecrets';
    this.secretDbSchemaPrefix = 'shepherd-db-schema-';
    this.secretJobPrefix = 'shepob-';
  }

  getAllSecrets() {
    return new Promise((resolve) => {
      const getSecrets = functions.httpsCallable(this.secretCall);
      getSecrets().then((result) => {
        resolve(result.data);
      });
    });
  }

  getDbSchemaSecretList() {
    return new Promise((resolve) => {
      this.getAllSecrets()
      .then((allSecrets) => {
        if (!allSecrets) resolve([]);
        const secrets = {};
        allSecrets.forEach((secret) => {
          if (!this.isDbSchemaSecret(secret)) return;
          secrets[secret] = this.getDbSchemaSecretNiceName(secret);
        });
        resolve(secrets);
      });
    });
  }

  getDbSchemaSecretNiceName(secret) {
    if (!this.isDbSchemaSecret(secret)) return secret;
    return secret.substring(this.secretDbSchemaPrefix.length - 1)
    .replace(/-([a-z])/g, g => ` ${g[1].toUpperCase()}`);
  }

  getJobSecretList() {
    return new Promise((resolve) => {
      this.getAllSecrets()
      .then((allSecrets) => {
        if (!allSecrets) resolve([]);
        const secrets = {};
        allSecrets.forEach((secret) => {
          if (!this.isJobSecret(secret)) return;
          secrets[secret] = this.getJobSecretNiceName(secret);
        });
        resolve(secrets);
      });
    });
  }

  getJobSecretNiceName(secret) {
    if (!this.isJobSecret(secret)) return secret;
    return secret.substring(this.secretJobPrefix.length - 1)
    .replace(/-([a-z])/g, g => ` ${g[1].toUpperCase()}`);
  }

  isJobSecret(secret) {
    return secret.substring(0, this.secretJobPrefix.length) === this.secretJobPrefix;
  }

  isDbSchemaSecret(secret) {
    return secret.substring(0, this.secretDbSchemaPrefix.length) === this.secretDbSchemaPrefix;
  }
}
